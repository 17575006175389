<script>
  import { colors } from '@/../tokens'
  import sizeMixin from '@/mixins/sizeMixin'

  /**
   * A spinning circle to indicate that the app is currently processing something and the user has to wait a little.
   */
  export default {
    mixins: [sizeMixin],
    props: {
      /** The size of the spinner, in Tailwind units. */
      size: {
        type: Number,
        default: 22,
      },
      /** Whether the circle should stop spinning and display a checkmark. */
      success: {
        type: Boolean,
        default: false,
      },
      /** The position of the overlay the spinner should be wrapped in. Leave as `undefined` for no overlay. */
      overlay: {
        type: String,
        default: undefined,
        validator: (value) => ['absolute', 'fixed'].includes(value),
      },
      /** Only if `overlay` is used. The translucent background color of the overlay. Leave as `undefined` for no background color (transparent). */
      overlayColor: {
        type: String,
        default: undefined,
        validator: (value) => ['white', 'dawn-lt3', 'dawn'].includes(value),
      },
    },
    data() {
      return {
        colors: colors,
        successCount: 0,
      }
    },
    watch: {
      success: {
        immediate: true,
        handler() {
          if (this.success) {
            this.successCount++
          }
        },
      },
    },
  }
</script>

<template>
  <!-- for purgecss: bg-white bg-dawn-lt3 bg-dawn -->
  <UtilityConditionalWrapper
    :wrap="Boolean(overlay)"
    tag="div"
    :class="`${overlay} inset-0 flex justify-center items-center ${
      overlayColor ? `bg-${overlayColor} bg-opacity-50` : ''
    }`"
  >
    <svg
      viewBox="0 0 72 72"
      :style="sizeStyle"
      :class="`fill-none ${!success ? 'animate-spin-fast' : ''}`"
      data-cy="navigation-spinner"
    >
      <defs>
        <linearGradient
          :id="`spinner-gradient-${_uid}`"
          x1="8.042%"
          y1="0%"
          x2="65.682%"
          y2="23.865%"
        >
          <stop :stop-color="colors.primary.default" stop-opacity="0" offset="0%" />
          <stop :stop-color="colors.primary.default" stop-opacity=".631" offset="63.146%" />
          <stop :stop-color="colors.primary.default" offset="100%" />
        </linearGradient>
      </defs>
      <g v-if="!success" transform="translate(1 1)">
        <path
          d="M 70 35 c 0 -18.94 -16.06 -35 -35 -35"
          class="stroke-2"
          :style="`stroke: url(#spinner-gradient-${_uid})`"
        />
        <circle cx="70" cy="35" r="1" class="fill-current text-primary" />
      </g>
      <!-- The key ensures the animation re-runs when `success` changes from `true` to `false` and back to `true` -->
      <g
        v-if="success"
        :key="`success-${successCount}`"
        class="stroke-2 stroke-current text-primary"
      >
        <circle cx="36" cy="36" r="35" class="success-circle" />
        <path d="M18.417,38.778l9.93,9.909L53.791,23.294" class="success-checkmark" />
      </g>
    </svg>
  </UtilityConditionalWrapper>
</template>

<style lang="postcss" scoped>
  @keyframes success-circle {
    0% {
      stroke-dashoffset: 240px;
    }

    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes success-checkmark {
    0% {
      stroke-dashoffset: 50px;
    }

    100% {
      stroke-dashoffset: 0;
    }
  }

  .success-circle {
    stroke-dasharray: 240, 240;
    animation: success-circle 0.5s ease-in-out backwards;
  }

  .success-checkmark {
    stroke-dasharray: 50, 50;
    animation: success-checkmark 0.3s ease-in-out 0.5s backwards;
  }
</style>
